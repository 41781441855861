export type ActivityManifestBundle = Record<string, unknown>
export type ManifestUrl = string | URL
export type ManifestIndex = [key: string, url: ManifestUrl]
export type Manifest = ManifestUrl | ManifestIndex | Request
export type MaybeActivityManifestBundle = ActivityManifestBundle | Manifest

export function isManifestIndex(manifest: MaybeActivityManifestBundle): manifest is ManifestIndex {
  const manifestIndex = manifest as ManifestIndex
  return manifestIndex?.length === 2 && typeof manifestIndex[0] === 'string' && typeof manifestIndex[1] === 'string'
}
